import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import Layout from "../components/layout/fr"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import ImageGallery from "../components/ui-sections/image-gallery"
import TextSection from "../components/ui-sections/text-section"
import NewsGrid from "../components/news/news-grid"
import previewImage from "../images/espais/principal_finestra.jpg"

const IndexPage = (props) => {
	const heroText = "Bienvenue";
	const textSectionText = "\"Chez Cal Pere del Maset nous voulons vous faire profiter d'un agréable moment. Nous vous offrons des produits de saison, dans un environnement de haute qualité. Le haut Pénédès, Subirats, Sant Pau d'Ordal. Nous voulons que vous vous sentiez comme chez vous. Bienvenue.\""
	return	(
		  <Layout location={props.location}>
		    <SEO 
		    	title="Page Principale" 
		    	lang="fr"
		    	description={textSectionText}
		    	image={previewImage}
		    />
		    <HeroImage 
		    	titleText={heroText}
		    	image="vinyards" 
		    />
		    <TextSection textSectionText={textSectionText} />
 		 		<Subsection>
 			    <Link to={"/fr/carta"}>
 			    	<Title>Consultez la nouvelle carte</Title>
 			    </Link>
 			    <StaticQuery
 			      query={graphql`
 			        # Replace this comment with the GraphQL query above
 			        {
 			          allHomepageImagesFrYaml {
 			            edges {
 			              node {
 			                title
 			                image {
 			                	id
 			                  childImageSharp {
 			                    fluid {
 			                      aspectRatio
 			                      originalImg
 			                      # Use gatsby-image later for better user experience
 			                      ...GatsbyImageSharpFluid
 			        }}}}}}}
 			      `}
 			      render={data => (
 			        <ImageGallery
 			          itemsPerRow={[1, 3]} // This will be changed to `[2, 3]` later
 			          images={data.allHomepageImagesFrYaml.edges.map(({ node }) => ({
 			          	id: node.image.id,
 			            ...node.image.childImageSharp.fluid,
 			            caption: `${node.title}`,
 			          }))}
 			        />
 			      )}
 			    />
 			  </Subsection>

		 		<Subsection>
			    <Link to={"/fr/noticies"}>
			    	<Title>Consultez les dernières Actualités</Title>
			    </Link>
			    <NewsGrid numberPreviews="2" />
			  </Subsection>
		  </Layout>
		)
}

const Title = styled.h1`
	text-align: center;
`

const Subsection = styled.div`
	margin-top: 60px;
	a {
	  text-decoration: none;
	  color: #000;
	  font-weight: bold;
	}
`

export default IndexPage
