module.exports = {
	/////// NAVIGATION////////
	"origensLink": "/fr/origens",
	"origensLabel": "Origine",
	"cuinaLink": "/fr/cuina",
	"cuinaLabel": "Cuisine",
	"espaisLink": "/fr/espais",
	"espaisLabel": "Espaces",
	"cellerLink": "/fr/celler",
	"cellerLabel": "Cave",
	"cartaLink": "/fr/carta",
	"cartaLabel": "Carte",
	"noticiesLink": "/fr/noticies",
	"noticiesLabel": "Actualités",
	"contacteLink": "/fr/contacte",
	"contacteLabel": "Contact",
	"reservesLink": "/fr/reserves",
	"reservesLabel": "Réservations",

	/////// FOOTER////////
	"direccioCarrer": "Ponent 20",
	"direccioPoblacio": "Sant Pau d'Ordal 08739 BCN",
	"labelGoogleMaps": "google maps",
	"numTelefon":"+34 93 899 30 28",
	"email": "info@calperedelmaset.com",
	"diesDinarOberts": "Du mardi au dimanche",
	"horesDinarOberts": "13:00 à 16:00",
	"diesSoparOberts": "Vendredi et Samedi",
	"horesSoparOberts": "21:00h à 23:00h",
	"diaTancat": "Lundi fermé",
	"segueixnos": "SUIVEZ NOUS",
	"treballaAmbNosaltres": "Travaille avec nous",
	"privacitat": "INTIMITÉ",
	"condicionsDus": "CONDITIONS D'UTILISATION",

	/////// ORIGENS////////
	"heroText": "",
	"sentenceText": "",
	"contentCaption": "",
	"contentTitle": "",
	"contentContent": "",
	"textSectionText": ""
}