import React from 'react';
import Layout from "./layout"

import messages from '../../i18n/fr';
import "@formatjs/intl-pluralrules/dist/locale-data/fr"

export default (props) => (
  <Layout
    {...props}
    messages={messages}
  />
);